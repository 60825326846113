
#input-form:focus{
    box-shadow: none;
    border: 1px solid lightgray
}

#login-col{
     padding: 0; 
     height: 100vh;
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-self: center;
     

}

#login-form{
    margin: auto auto;
}

/* SignUp page */
#signUp-col-1{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#signIn-col{
    text-align: center;
    background-color: #e6e6e6;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    
}

#signUp-col-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}

.theme-form .form-group {
    margin-bottom:5px!important;
}

#mobile_scroll::-webkit-scrollbar{
    display: none;
}

@media only screen and (max-width: 1000px) {
    #signIn-col{
        order: 1;
    }
}
@media only screen and (max-width: 768px) {

    #signIn-col{
        height: 35%;
        order: 1;
        padding: 60px;
    }

    #login-col{ 
        height: 77vh;
    }

    #login-form{
        max-width: 80%;
        width: 90%;
    }

    #registerForm{
        font-size: 14px;
    }
    
}

@media only screen and (max-width: 375px) {

    #login-col{ 
        height: 70vh;
    }

}

@media only screen and (max-width: 320px) {

    #registerForm{
        
        font-size: 12px;
    }
}

