

.rec.rec-arrow {
    background-color: #f9b700;
}

/* round buttons on hover */
.rec.rec-arrow:hover {
    border-radius: 50%;
    background-color: #103c5a;
}

button.rec.rec-arrow{
    background-color:white;
    box-shadow: 0 0 1px 3px  #103c5a;
  }
  
  button.rec-arrow:hover, button.rec-arrow:active, button.rec-arrow:focus  {
    box-shadow: 0 0 1px 3px  #103c5a;
  }


button.rec-dot{
    background-color:white;
    box-shadow: 0 0 1px 3px  #103c5a;
  }
  
  button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
    box-shadow: 0 0 1px 3px  #103c5a;
  }

.rec.rec-arrow:focus {
    border-radius: 50%;
    background-color: #103c5a;
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;
}

.rec-pagination .rec-dot_active{
    background-color: #f9b700;
    box-shadow: 0 0 1px 3px #103c5a;
}

@media(max-width: 768px){
    #carousel_description{
        font-size: 12px ;
    }
}