// override breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px
);

@import '~bootstrap-scss/bootstrap.scss';
@import 'color';
// @import "chartist.scss";
// @import "themify.scss";
@import 'material-design-icon.scss';
// @import "typicon-icon.scss";
// @import "pe7-icon.scss";
// @import "ionic-icon.scss";
// @import "simple-line-icon.scss";
// @import "whether-icon.scss";
// @import "icofont.scss";
// @import "flag-icon.scss";
@import 'icoicon/icons';
@import 'fontawesome.scss';
// @import "sticky/sticky";
// @import "todo.scss";
// @import "open-iconic.scss";
@import './custom_CSS/auth/login_signUp.scss';
@import './custom_CSS/dashboard/dashboard.scss';
@import './custom_CSS/dashboard/carousel.scss';
@import './custom_CSS/dashboard/dashboard_carousel.scss';
// @import './custom_CSS/promotion/promotionLanding.scss';
// ReactToastify
// @import '~react-toastify/dist/ReactToastify.css';
//  Dropzone
// @import '~react-dropzone-uploader/dist/styles.css';
